import styled from "styled-components";

export const Title = styled.h1 `
    font-family: "Inter", "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: ${v => v.color || 'rgb(139, 152, 173)'};
    text-align: center;
`;


export const SubTitle = styled.h3 `
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 
        'Lucida Grande', 'Lucida Sans Unicode', 
        Geneva, Verdana, sans-serif;
    color: rgb(139, 152, 173);
`;

export const BannerTitle = styled.h2 `
    color: rgb(246, 245, 244);
    text-align: ${px => px.ta || 'center'};;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 
    'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
`;

export const ParagraphBase = styled.p `
    font-family: "Inter", "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-align: ${px => px.ta || 'justify'};
    line-height: 1.5;
    font-size: 18px;
`;