import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import { SubTitle, ParagraphBase } from "../../components/text"
import { useEffect, useState } from 'react';

export const Artic2 = () => {
  const [artics, setArtic] = useState([]);

  useEffect(() => {
    const searchArtic = async () => {
      try {
        const resp = await fetch(process.env.PUBLIC_URL + "/articleList.json");
        const articData = await resp.json();
        setArtic(articData);
      } catch (error) {
        console.log(error);
      }
    }
    searchArtic();
  }, []);

  return (
    <>
    {
      artics.map((artic) => {
        return (
          <>
            <article>
              <SubTitle>{artic.title}</SubTitle>
              <div className="metaData">
                  <p className="meta"><CalendarMonthIcon sx={{ fontSize: 15 }}></CalendarMonthIcon> <time>{artic.date}</time></p>
                  <p className="meta"><AccessTimeIcon sx={{ fontSize: 15 }}></AccessTimeIcon> 7 min read</p>
              </div>
              <ParagraphBase>{artic.description}</ParagraphBase>
              <hr className="lineX"/>
            </article>
          </>
        )
      })
    }
    </>
  );
}

/*function Artic() {
    return (
      <>
        <article>
            <SubTitle>One Article</SubTitle>
            <div className="metaData">
                <p className="meta"><CalendarMonthIcon sx={{ fontSize: 15 }}></CalendarMonthIcon> <time>02/05/2024</time></p>
                <p className="meta"><AccessTimeIcon sx={{ fontSize: 15 }}></AccessTimeIcon> 7 min read</p>
            </div>
            <ParagraphBase>A dive into the PE file format - LAB 1: 
              Writing a PE Parser Introduction In the previous posts we’ve 
              discussed the basic structure of PE files, In this pos...</ParagraphBase>
            <hr className="lineX"/>
        </article>
      </>
    )
}

export default Artic*/