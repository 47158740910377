import AppBar from "../appBar/appBar";
import AppFoot from "../appFoot/appFoot";

import { Container } from "../../components/container";
import { Title, ParagraphBase, BannerTitle } from "../../components/text";

function AppBody() {
    return (
        <>
        <AppBar/>
        <Container maxW="800px" mt="170px">
            <Container maxW="500px">
                <Title color="rgb(25, 245, 170)">~# whoami</Title>
                <ParagraphBase ta='center'>
                    If you're here then probably you want to know a little about who I am and what I do.
                </ParagraphBase>
            </Container>
            
            <Container maxW="100%" mt='50px'>
                <BannerTitle ta='left'>
                    Hello!
                </BannerTitle>
                <ParagraphBase>
                    As of February 2021 I have been studying a BSc degree in Computer Science at an 
                    university in Brazil. Since 2017 I have been studied Cyber Security focusing offensive 
                    security and Ethical Hacking. At that time I started to study programming and computer 
                    principles. I currently work as Cyber Security Engineer and Security Tester for a CDN 
                    oriented to cyber security, so I ended up acting in Blue Team areas as well.
                </ParagraphBase>

                <ParagraphBase>
                    In addition to red team operations, I like to write code that helps me with day-to-day 
                    challenges, I use to publish them such that can be useful to other peoples as well in 
                    learning phase or who is needing that automation.
                </ParagraphBase>

                <ParagraphBase>
                    The purpose of this blog is primarily to serve as a space for me to upload write-ups 
                    and introductions to anything I come across in my studies that I find particularly 
                    interesting or would like to see a little more about the topic on internet, 
                    but also to document my journey into the field of information security. 
                    I hope that you will find my writing informative, if not enjoyable!
                </ParagraphBase>

                <ParagraphBase>
                    - p.f. | farinap5
                </ParagraphBase>
            </Container>
        </Container>
        <AppFoot />
        </>
    );
}

export default AppBody;