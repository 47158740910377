import { Toolbar, Typography } from "@mui/material";
import './appBar.css';
import tk1v2 from '../../img/tk1v2.png';


function AppBar() {
    return (
        <>
            <Toolbar className="toolBar">
                <img src={tk1v2} alt="tk1v2" className="iconImg"/>
                <div className="navLiks">
                    <Typography className="link" href="/">Home</Typography>
                    <Typography className="link" href="/whoami">Whoami</Typography>
                    <Typography className="link" href="https://github.com/farinap5">Github</Typography>
                </div>
            </Toolbar>
        </>
    );
}

export default AppBar;