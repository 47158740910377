import './appFoot.css';
import React from "react";
import { Box, Typography } from '@mui/material';
import styled from "styled-components";

import LockIcon from '@mui/icons-material/Lock';
import MessageIcon from '@mui/icons-material/Message';
import GitHubIcon from '@mui/icons-material/GitHub';

//import { useState } from "react";

const FooterContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1000px;
    margin: 0 auto;
    mar
`;

function AppFoot() {
    return (
        <Box className="box-style">
            <FooterContainer>
                <hr className="Horule"/>
                <div className="footLinks">
                    <Typography className="link" href=""><LockIcon sx={{ fontSize: 25 }}></LockIcon></Typography>
                    <Typography className="link" href=""><MessageIcon sx={{ fontSize: 25 }}></MessageIcon></Typography>
                    <Typography className="link" href=""><GitHubIcon sx={{ fontSize: 25 }}></GitHubIcon></Typography>
                </div>
            </FooterContainer>
        </Box>
    );
}

export default AppFoot;