import { BrowserRouter, Routes, Route } from "react-router-dom";
import Appbody from "./pages/Home/mainApp"
import AppBody from "./pages/About/AboutApp";

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route index element={<Appbody />} />
      <Route path="whoami" element={<AppBody />} />
    </Routes>
  </BrowserRouter>
  );
}

export default App;
