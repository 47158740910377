import {Box,  Button} from "@mui/material";
import Card from '@mui/material/Card';
import { Container } from "../../components/container";

import './mainApp.css';
import AppBar from "../appBar/appBar";
import AppFoot from "../appFoot/appFoot";
import { Artic2 } from "./articDef";
import {SubTitle, BannerTitle, ParagraphBase } from "../../components/text"

import tk1v2 from '../../img/avatar.png'


function PortfBox() {
  return (
    <>
      <Box className="portBox">
      <img src={tk1v2} alt="tk1v2" className="avatarBod"/>
          <p className="Banner">I am a computer science student with a focus on cyber-security. 
            I enjoy challenging positions in computer science and cyber-security.</p>
          <p className="Banner">I like being where these two areas meet to contribute 
            to the development of new tactics and tools for day-to-day challenges.</p>
      </Box>
    </>
  )
}

function ArticBox() {
  return (
    <>
      <Box className="ArticBox">
          <Box className="Banner">
              <h1>Articles</h1>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
              <br/>
          </Box>
          <Artic2/>
      </Box>
    </>
  )
}

function CardContainer() {
  return (
    <>
    <Container>
      <Container maxW="500px">
        <BannerTitle>Cool Projects</BannerTitle>
        <ParagraphBase ta='center'>
          I like to code. It makes me feel free and confident the about the tools I create or use.
        </ParagraphBase>
      </Container>
      <Container className="cardContainer" mt="50px">
        <Card className="cardProj" sx={{ bgcolor: '#283771' }}>
          <SubTitle>Venera</SubTitle>
          <p>A modular exploitation framework extensible with Lua.</p>
          <Button href="#text-buttons">Learn more</Button>
        </Card>

        <Card className="cardProj" sx={{ bgcolor: '#602a63' }}>
          <SubTitle>PurpleCommand</SubTitle>
          <p>Malleable adversary-emulation tool for Linux (Not Available)</p>
          <Button href="#text-buttons">Learn more</Button>
        </Card>

        <Card className="cardProj" sx={{ bgcolor: '#204955' }}>
          <SubTitle>SurrealCLI</SubTitle>
          <p>Full-feature SurrealDB client written in Golang </p>
          <Button href="#text-buttons">Learn more</Button>
        </Card>
      </Container>
    </Container>
    </>
  )
}

function AppBody() {
    return (
        <>
        <AppBar/>
        <Container className="container">
          <PortfBox />
          <ArticBox />
        </Container>
        <CardContainer/>
        <AppFoot />
        </>
    );
}

export default AppBody;